import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { css } from "@emotion/core"

const Article = ({ data }) => (
  <Layout>
    <SEO title={data.shopifyArticle.title} meta={data.shopifyArticle.bodySummary} />
    <div css={css`
        margin-top: 1rem;
        @media (max-width: 648px) {
            margin-top: 0;
            padding: 1rem;
        }
    `}>
        <h1>{data.shopifyArticle.title}</h1>
        <Img fluid={data.shopifyArticle.image.localFile.childImageSharp.fluid} css={css`
            margin-bottom: 2rem;
        `}/>
        <div dangerouslySetInnerHTML={{__html: data.shopifyArticle.contentHtml}}  css={css`
            text-align: center;

            h1 {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 13px;
                margin-top: 2px;

                &:not(:first-child) {
                    margin-top: 16px;
                }
            }

            h3 {
                text-align: left;
            }

            font-size: 18px;
            line-height: 28px;
            margin-bottom: 0;

            p {
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: -6px;
                }
            }

            p.legal {
                margin-top: 14px;
                font-size: 16px;
                line-height: 16px;
                &:last-child {
                    margin-bottom: -4px;
                }
            }

            img {
                display: block;
                width: 100%;
                @media (max-width: 648px) {
                    margin-left: -1rem;
                    max-width: calc(100% + 2rem);
                    width: calc(100% + 2rem);
                }
            }

            table {
                width: 100%;
                font-size: 20px;
                line-height: 36px;
                text-align: left;
                border-spacing: 2px;
                border-collapse: separate;

                border: none;

                tbody {
                    border: none;
                }

                tr {
                    border: none;
                }

                tr:nth-child(odd) {
                    background-color: #fbfbfb;
                }
                tr:nth-child(even) {
                    background-color: #fdfdfd;
                }

                td {
                    padding: 10px;
                    border: none;
                }

                /* To make the ReactMarkdown render as inline */
                td > * * {
                    display: inline;
                }

                a {
                    color: inherit;
                }
            }
        `}></div>
    </div>
  </Layout>
)

export default Article

export const query = graphql`
  query($id: String!) {
    shopifyArticle(id: { eq: $id }) {
      id
      title
      url
      contentHtml
      publishedAt(formatString: "ddd, MMMM Do, YYYY")
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`